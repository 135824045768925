export enum TYPES {
  SET_IS_ONBOARDING_COMPLETED = 'ONBOARDING/SET_IS_ONBOARDING_COMPLETED',
  SET_ONBOARDING_STEP = 'ONBOARDING/SET_ONBOARDING_STEP',
  SET_ONBOARDING_PROFILE = 'ONBOARDING/SET_ONBOARDING_PROFILE',
  RESET_ONBOARDING_DATA = 'ONBOARDING/RESET_ONBOARDING_DATA',
}

export interface State {
  isOnboardingCompleted: boolean;
  onboardingProfile: {
    name?: string;
    gender?: string;
    relationship?: string;
    date?: string;
  };
  step: string;
  goal: string;
}

interface SetOnboardingCompleted {
  type: typeof TYPES.SET_IS_ONBOARDING_COMPLETED;
  payload: boolean;
}

interface SetOnboardingProfile {
  type: typeof TYPES.SET_ONBOARDING_PROFILE;
  payload: any;
}

interface SetOnboardingStep {
  type: typeof TYPES.SET_ONBOARDING_STEP;
  payload: {
    step: string;
  };
}

interface ResetOnboardingData {
  type: typeof TYPES.RESET_ONBOARDING_DATA;
}

export type ActionTypes = SetOnboardingCompleted | SetOnboardingProfile | SetOnboardingStep | ResetOnboardingData;
