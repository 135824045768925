import React, { FC, useEffect } from 'react';
import { StyleSheet, View, Image, TouchableOpacity, ScrollView } from 'react-native';
import Modal from 'react-native-modal';
import { BOTTOM_OFFSET, fs, paddingHorizontal, paddingVertical, sw, color } from '@wowmaking/ui/src/utils';

import Analytics from 'analytics';
import { useAppSelector, useAppDispatch } from 'store';
import { getProcessingReports } from 'store/upsails/selectors';
import { closeCurrentModal } from 'store/modals/actions';
import { t2 } from 'localization';
import Text from 'components/text';
import PersonalReportsList from 'components/personal-reports';
import Button from 'components/button';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import { OPEN_REPORTS_PLACES } from 'constants/upsale';
import { windowHeight } from 'constants/sizes';

import ICON_CROSS from '../images/beige-cross.png';
import STARS_IMG from '../images/stars.png';

const PersonalReportsProcessModal: FC = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(state => state.modals.activeModal === MODALS.PERSONAL_REPORTS_PROCESS);
  const onClose = useAppSelector(state => state.modals.params?.onClose);
  const userReports = useAppSelector(getProcessingReports);

  useEffect(() => {
    if (isVisible) {
      Analytics.track('Personal_Report_Process_Modal_Open');
    }
  }, [isVisible]);

  const handleClosePress = () => {
    Analytics.track('Personal_Report_Process_Modal_Close');
    dispatch(closeCurrentModal());
  };

  const handleButtonPress = () => {
    Analytics.track('Personal_Report_Process_Modal_Button_Click');
    dispatch(closeCurrentModal());
  };

  const handleSkip = () => {
    Analytics.track('Personal_Report_Process_Modal_Skip');
    dispatch(closeCurrentModal());

    if (typeof onClose === 'function') {
      setTimeout(onClose, 500);
    }
  };

  return (
    <Modal style={styles.wrapper} isVisible={isVisible} animationInTiming={500} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <View style={styles.container}>
        <View style={styles.header}>
          <TouchableOpacity style={styles.cross} onPress={handleClosePress}>
            <Image source={ICON_CROSS} />
          </TouchableOpacity>

          <View style={styles.top} pointerEvents="none">
            <Image style={styles.image} source={STARS_IMG} resizeMode="contain" />
          </View>

          <View style={styles.headerContainer}>
            <Text font="Philosopher" style={styles.title}>
              {t2('PERSONAL_REPORTS.PROCESS_MODAL.TITLE')}
            </Text>
            <Text style={styles.subTitle}>{t2('PERSONAL_REPORTS.PROCESS_MODAL.TEXT')}</Text>
          </View>
        </View>

        <ScrollView style={styles.scrollView} contentContainerStyle={styles.contentContainer}>
          <PersonalReportsList place={OPEN_REPORTS_PLACES.PROCESS_MODAL} reports={userReports} onPress={handleClosePress} />
        </ScrollView>

        <View style={styles.footer}>
          <Button
            titleStyle={styles.btnTitle}
            style={styles.button}
            title={t2('PERSONAL_REPORTS.PROCESS_MODAL.BTN_TITLE')}
            onPress={handleButtonPress}
          />

          <Button
            isTransparent
            title={t2('PERSONAL_REPORTS.PROCESS_MODAL.SKIP_BTN_TITLE')}
            fontFamily="OpenSans"
            titleStyle={styles.buttonSkip}
            onPress={handleSkip}
          />
        </View>
      </View>
    </Modal>
  );
};

export default PersonalReportsProcessModal;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    height: 'auto',
    flexGrow: 0,
    maxHeight: windowHeight * 0.9,
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    paddingBottom: BOTTOM_OFFSET + paddingVertical(20),
  },
  scrollView: {
    width: '100%',
  },
  cross: {
    position: 'absolute',
    top: 0,
    right: 0,
    paddingVertical: 0,
    paddingRight: paddingHorizontal(20),
    zIndex: 1,
  },
  header: {
    marginTop: paddingVertical(20),
    width: '100%',
    paddingBottom: paddingVertical(10),
  },
  headerContainer: {
    width: '100%',
    paddingHorizontal: paddingHorizontal(20),
  },
  top: {
    width: '100%',
    height: 71,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    position: 'absolute',
    alignSelf: 'center',
    width: '100%',
  },
  title: {
    color: COLORS.BEIGE_2,
    marginTop: -paddingVertical(5),
    fontSize: fs(26),
    fontWeight: '700',
    lineHeight: fs(33),
    textAlign: 'center',
  },
  subTitle: {
    marginTop: paddingVertical(10),
    fontSize: fs(17),
    lineHeight: fs(22),
    color: color(COLORS.GOLDEN, 0.8),
    textAlign: 'center',
  },
  footer: {
    paddingHorizontal: paddingHorizontal(20),
    paddingTop: paddingVertical(20),
  },
  contentContainer: {
    paddingTop: paddingVertical(10),
    paddingHorizontal: paddingHorizontal(20),
    alignItems: 'center',
  },
  button: {},
  btnTitle: {
    fontWeight: '600',
    fontSize: fs(20),
  },
  buttonSkip: {
    fontSize: fs(14),
    color: COLORS.AQUA,
    fontWeight: '400',
    textDecorationLine: 'underline',
  },
});
