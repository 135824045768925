import { createSelector } from 'reselect';

import { RootState } from 'store';
import { getPalmReport as getUpsalePalmReport } from 'store/upsails/selectors';
import { getDiffSecondsFromNow } from 'utils/date';

import { HANDS } from './types';

const getReadyTimers = (state: RootState) => state.palmReading.readyTimers;
const getPreviews = (state: RootState) => state.palmReading.previews;
const getHand = (_: RootState, hand: HANDS) => hand;

export const getReportReadyDate = createSelector(
  [getReadyTimers, getUpsalePalmReport, getHand],
  (timers, report, hand): Date | number | undefined => {
    const timer = timers?.[hand];
    const seconds = report?.readyDate ? getDiffSecondsFromNow(report.readyDate) : 0;
    if (hand === HANDS.LEFT && seconds > 0 && report?.readyDate) {
      return report.readyDate;
    }

    if (timer && getDiffSecondsFromNow(timer) > 0) {
      return timer;
    }

    return undefined;
  },
);

export const getPreviewURI = createSelector([getPreviews, getHand], (previews, hand) => {
  return previews[hand]?.uri;
});
