import api from 'api';

import { ACCRUE_TIME, AccrueTimeRequestParams } from './constants';

/**
 * @param params.time number of seconds
 * @param params.type 'unpaid' - one-time accrual for unpaid user, 'first' - one-time accrual after sub, 'cycle' - recurring accrual upon subscription renewal
 * @param params.increaseFreeBalance 'true' - increases free balance
 */
const accrueTime = async (params: AccrueTimeRequestParams): Promise<void> => {
  const { time, type, increaseFreeBalance } = params;
  try {
    const response = await api.fetch(ACCRUE_TIME, {
      method: 'POST',
      data: {
        time,
        type,
        freeBalance: increaseFreeBalance,
      },
    });

    return response;
  } catch (error) {
    console.log('ERROR: Accrue free time', error);
  }
};

export default accrueTime;
