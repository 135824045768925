import _ from 'lodash';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import RNRestart from 'react-native-restart';
import { createAction } from 'redux-actions';
import queryString from 'query-string';
import DeviceProps from '@magnus/react-native-device-props';

import Analytics from 'analytics';
import api from 'api';
import * as AuthAPI from 'modules/auth/api';
import * as ROUTES from 'constants/routes';
import * as MODALS from 'constants/modals';
import { AppDispatch, AppGetState } from 'store';
import { initsAfterAuth, setInitialUrl, toggleOverlayLoaderActivity } from 'store/app/actions';
import { setPalmResult } from 'store/palm-reading-daily/actions';
import { reset } from 'store/navigation/actions';
import { isStandalone } from 'utils/pwa';
import { ProfileData, UserParams } from 'api/profile/interfaces';
import { setProfile, resetUserProfileData, setUserParams } from 'store/profile/actions';
import { goToNextStep, setOnboardingCompleted, setOnboardingStep } from 'store/onboarding/actions';
import { addToQueue, closeCurrentModal, showModal } from 'store/modals/actions';
import { ONBOARDING_IDS } from 'constants/onboarding-types';

import { generatePWAManifest } from '../utils';
import firebaseAuth from '../utils/firebase-auth';

import { AUTH_TYPES, WEB_UUID_AUTH_MODE, ProcessWebUuidParams, FirebaseUser } from './types';

const setUserCredentials = createAction(AUTH_TYPES.SET_USER_CREDENTIALS);
const setWebUUID = createAction(AUTH_TYPES.SET_WEB_UUID);
const setDeferredAppWebUuidMode = createAction(AUTH_TYPES.SET_DEFERRED_APP_WEB_UUID_MODE);
const setPrevEvtruckUser = createAction(AUTH_TYPES.SET_PREV_EVTRUCK_USER);
const setOneTimeToken = createAction(AUTH_TYPES.SET_ONE_TIME_TOKEN);
const setFirebaseEmail = createAction(AUTH_TYPES.SET_FIREBASE_EMAIL);
const setCompleteRegistrationShows = createAction(AUTH_TYPES.SET_COMPLETE_REGISTRATION_SHOW_COUNTER);
const setCompleteRegistrationShown = createAction(AUTH_TYPES.SET_COMPLETE_REGISTRATION_SHOWN);
const setIsRegistrationCompleted = createAction(AUTH_TYPES.SET_IS_REGISTRATION_COMPLETED);
const setAfterReload = createAction(AUTH_TYPES.SET_AFTER_RELOAD);
export const setForceLogout = createAction(AUTH_TYPES.SET_FORCE_LOGOUT);
export const resetAuthData = createAction(AUTH_TYPES.RESET_DATA);

const IDFM_STORAGE_KEY = '@RNMV/IDFM';

interface Params {
  isPWA?: boolean;
  uuid: string;
  oneTimeToken?: string;
}

const firebaseLogin = () => {
  return async (_: AppDispatch, getState: AppGetState): Promise<FirebaseUser> => {
    const {
      auth: { webUUID },
    } = getState();

    const uuid = webUUID || (await DeviceInfo.getUniqueId());

    // не сильно нам подходит, но такая оставлю здесь
    // if (accessToken) {
    //   const firebaseCurrentUser = await firebaseAuth.getCurrentUser();
    //   if (firebaseCurrentUser?.uuid === uuid) {
    //     return firebaseCurrentUser;
    //   }
    // }

    const customToken = await AuthAPI.createCustomToken(uuid);
    const userCred = await firebaseAuth.signInWithCustomToken(customToken);
    const idToken = await userCred.user.getIdToken();
    const { email, uid } = userCred.user;

    return { idToken, email, uuid: uid };
  };
};

/**
 * initAuth - Main Auth Flow;
 * Authenticate user by uuid/webUUID or one time token.
 *
 * There are few different ways to login: 1. Using socials, 2. mail/password 3. OTP
 * After success login we will get uuid/webUUID and start use it for auth
 */
let waitForDeeplinkHandle = false;
export const initAuth = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    console.log('>>>>>>> initAuth 2222');
    const {
      auth: { webUUID, oneTimeToken, forceLogout, deferredAppWebUuidMode, prevEvtruckUser },
    } = getState();

    console.log('> webUUID', webUUID);
    console.log('> oneTimeToken', oneTimeToken);

    try {
      if (waitForDeeplinkHandle) {
        throw 'Auth prevented. Waiting deeplink handle';
      }

      // Auth by socials
      if (Platform.OS === 'web') {
        try {
          const redirectResult = await firebaseAuth.getRedirectResult();
          if (redirectResult?.idToken) {
            console.log('>>> redirectResult !', redirectResult);
            const response = await AuthAPI.authByFirebaseIdToken(redirectResult?.idToken);
            dispatch(handleSuccessAuth(response, redirectResult.email));
            return true;
          }
        } catch (e) {
          console.log('> Auth by socials error', e);
        }
      }

      // попадет на велком, в initAppNavigation
      if (!webUUID && !oneTimeToken && Platform.OS === 'web') {
        Analytics.trackEvent('auth', 'have_no_web_uuid');
        return false;
      }

      const uuid = webUUID || (await DeviceInfo.getUniqueId());

      const params: Params = { uuid, oneTimeToken };
      if (webUUID) {
        params.isPWA = isStandalone();
      }
      Analytics.track('auth_start', params);

      if (forceLogout) {
        throw 'User Logouted';
      }

      let response;

      if (oneTimeToken) {
        response = await AuthAPI.authOneTimeCode(oneTimeToken);
        const { email } = await dispatch(firebaseLogin());
        dispatch(handleSuccessAuth(response, email));
        Analytics.track('auth_onetime_code_success', params);
      } else {
        // firebase auth
        const { idToken, email } = await dispatch(firebaseLogin());
        response = await AuthAPI.authByFirebaseIdToken(idToken);
        dispatch(handleSuccessAuth(response, email));
        Analytics.track('auth_firebase_success', params);
      }

      // Отложенный трекинг app_web_uuid для веба, там могут быть ситуации когда
      if (deferredAppWebUuidMode) {
        Analytics.track('app_web_uuid', { uuid: webUUID, mode: deferredAppWebUuidMode, deferredTrack: true, prevEvtruckUser });
        dispatch(setDeferredAppWebUuidMode(''));
      }

      Analytics.track('auth_success', { cross_session_number: response?.profile?.data?.cross_session_number || 0 });
    } catch (error: any) {
      console.log('> [ERROR] while authentication', error);
      Analytics.track('auth_error', { error: error?.message || error });
      reset(ROUTES.AUTHORIZATION);
    }
  };
};

export const showAuthSuccessModal = email => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { webUUID, isRegistrationCompleted, firebaseEmail: prevEmail },
      navigation: { currentRoute },
    } = getState();

    console.log('>>> showAuthSuccessModal');

    // не показываем для пользователя апы (не веб), который не установил пароль
    if (Platform.OS !== 'web' && !webUUID && !isRegistrationCompleted) {
      return false;
    }

    const excludeRoutes = [ROUTES.AUTH_SUCCESS, ROUTES.AUTH_BY_EMAIL, ROUTES.LOGIN];
    if (email && email !== prevEmail && !excludeRoutes.includes(currentRoute)) {
      console.log('> SHOW AUTH SUCCESS MODAL');
      dispatch(showModal(MODALS.AUTH_SUCCESS, { email }));
    }
  };
};

// это для успешной авторизщации через initAuth, НЕ через мыло/otp/социалки
const handleSuccessAuth = (response, firebaseEmail) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { webUUID },
      onboarding: { isOnboardingCompleted },
    } = getState();

    dispatch(processAuthResponse(response)); // saving to store

    const email = firebaseEmail || response?.profile?.email || '';

    // Check is firebase user complete registration (has email and password)
    if (firebaseEmail) {
      try {
        const methods = await firebaseAuth.getSingInMethods(firebaseEmail);
        if (methods.includes('password')) {
          dispatch(setIsRegistrationCompleted(true));
          Analytics.setUserProperty('full_account', true);
        }
      } catch (e) {
        console.log('> Get Firebase sign in methods error', e);
      }
    }

    // call after previous block with setIsRegistrationCompleted
    dispatch(showAuthSuccessModal(email));
    dispatch(setFirebaseEmail(email)); // set after auth success modal

    if (!isOnboardingCompleted && email) {
      dispatch(setOnboardingCompleted());
    }

    // Handle all specific logic for WEB in this if
    if (Platform.OS === 'web') {
      generatePWAManifest(webUUID);
      return true;
    }

    // Handle all specific logic for MOBILE APP here
    const userParams = response.profile?.profileData?.userParams;
    let newUserParams: UserParams = {};
    if (!userParams?.app_installed) {
      newUserParams.app_installed = true;
    }
    if (!userParams?.adjust_id) {
      const [adjust_id, idfa, idfm] = await Promise.all([DeviceProps.getAdjustId(), DeviceProps.getIDFA(), DeviceProps.getIDFM()]);
      newUserParams = { ...newUserParams, adjust_id, idfa, idfm };
    }
    if (Object.keys(newUserParams)?.length > 0) {
      dispatch(setUserParams(newUserParams));
    }
  };
};

export const finalizeWebAuth = (initialUrl?: string, reload = false) => {
  return (dispatch: AppDispatch) => {
    dispatch(setOnboardingCompleted());

    if (initialUrl) {
      dispatch(setInitialUrl(initialUrl));
    }

    if (reload) {
      dispatch(setAfterReload(true));
      dispatch(setPalmResult(null));
      setTimeout(() => {
        RNRestart.Restart();
      }, 200); // TODO replace with persistor flush
    }
  };
};

export const signUpWeb = () => {
  return () => {
    if (Platform.OS !== 'web') {
      return false;
    }
    const idfm = window.localStorage.getItem('@RNMV/IDFM');
    const url: any = queryString.stringifyUrl({
      url: process.env.REACT_APP_WEB_SUB_URL as unknown as string,
      query: { idfm },
    });
    window.location = url;
  };
};

/* LOG IN  BY OTP START */
export const authWithOTP = (code: string, email: string) => {
  return async (dispatch: AppDispatch) => {
    const response = await AuthAPI.authWithOTP(code, email);
    dispatch(handleWebAuth(response, WEB_UUID_AUTH_MODE.OTP));
    dispatch(setFirebaseEmail(email)); // Don't show success page again
    return response;
  };
};
/* LOG IN  BY OTP EMD */

/* LOG IN FLOW START */
export const loginByEmail = (email: string, password: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const userCredential = await firebaseAuth.signInWithEmailAndPassword(email, password);
      const token = await userCredential.user.getIdToken();
      const response = await AuthAPI.authByFirebaseIdToken(token);
      Analytics.trackEvent('auth', 'by_email_success');
      dispatch(handleWebAuth(response, WEB_UUID_AUTH_MODE.EMAIL_PASSWORD));
      dispatch(setFirebaseEmail(email)); // Don't show success page again
    } catch (error) {
      console.warn('> [ERROR] AUTH by email', error);
      const validationError = firebaseAuth.getFirebaseErrorCode((error as Error).message);
      throw validationError;
    }
  };
};


export const loginWithGoogle = () => {
  return async (dispatch: AppDispatch) => {
    console.log('>>>>>>> loginWithGoogle');
    try {
      const result = await firebaseAuth.signInWithGoogle();
      console.log('>>>> result', result);
      // const response = await AuthAPI.authByFirebaseIdToken(token);
      // Analytics.trackEvent('auth', 'by_google_success');
      // dispatch(handleWebAuth(response, WEB_UUID_AUTH_MODE.EMAIL_PASSWORD));
      // dispatch(setFirebaseEmail(email)); // Don't show success page again
    } catch (error) {
      console.warn('> [ERROR] AUTH with google', error);
      const validationError = firebaseAuth.getFirebaseErrorCode((error as Error).message);
      throw validationError;
    }
  };
};

export const resetPassword = (email: string) => {
  return async (_dispatch: AppDispatch) => {
    try {
      await AuthAPI.resetProfilePassword(email);
      Analytics.trackEvent('auth', 'send_reset_password_email_success');
    } catch (error) {
      console.warn('> [ERROR] Send reset password email ERROR', error);
      const validationError = firebaseAuth.getFirebaseErrorCode((error as Error).message);
      throw validationError;
    }
  };
};

export const showCompleteRegistrationModal = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      auth: { isRegistrationCompleted, completeRegistrationShowsCounter, isCompleteRegistrationShown, firebaseEmail },
      remoteConfig: {
        remoteConfigParams: {
          completeRegistrationModal: { enable, showSessions, startSession },
        },
      },
    } = state;

    const currentSession = (Analytics.getSessionNumber() ?? 0) + 1;

    // нужен именно email из фаера (шо толку сетать пароль в никуда)
    if (!firebaseEmail?.length) {
      return false;
    }

    if (isRegistrationCompleted) {
      return false;
    }

    if (!enable) {
      return false;
    }

    if (isCompleteRegistrationShown) {
      return false;
    }

    if (currentSession < startSession) {
      return false;
    }

    if (completeRegistrationShowsCounter >= showSessions) {
      return false;
    }

    dispatch(addToQueue(MODALS.COMPLETE_REGISTRATION));
    dispatch(setCompleteRegistrationShows(completeRegistrationShowsCounter + 1));
    dispatch(setCompleteRegistrationShown(true));
  };
};

export const closeCompleteRegistrationModal = () => {
  return (dispatch: AppDispatch) => {
    dispatch(closeCurrentModal());
  };
};

export const setPasswordForAccount = (password: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await firebaseAuth.updateUserPassword(password);
      dispatch(setIsRegistrationCompleted(true));
      Analytics.setUserProperty('full_account', true);
    } catch (error) {
      console.log('ERROR setPasswordForAccount', (error as Error).message);
      throw error;
    }
  };
};
/* LOG IN FLOW END */

const handleWebAuth = (response: any, authMode: WEB_UUID_AUTH_MODE) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const { webUUID } = getState().auth;

    console.log('>>>>>>>> handleWebAuth');
    console.log('> webUUID', webUUID);

    dispatch(processAuthResponse(response));

    const uuid = response?.profile?.device_id;
    console.log('> uuid from profile', uuid);

    if (Platform.OS !== 'web') {
      const deviceId = await DeviceInfo.getUniqueId();
      // это не webUUID, а пользователь из мобилки, который сделал покупуку веб пурча и указал мыло
      if (uuid === deviceId) {
        dispatch(setWebUUID(''));
        return false;
      }
    }

    console.log('> Set new webUUID', webUUID);
    dispatch(setWebUUID(uuid));

    if (Platform.OS === 'web' && uuid !== webUUID) {
      Analytics.track('login_to_other_user', { webUUID: uuid });

      const prevEvtruckUser = window.localStorage.getItem(IDFM_STORAGE_KEY); // for analytic purposes
      dispatch(setPrevEvtruckUser(prevEvtruckUser));
      dispatch(setDeferredAppWebUuidMode(authMode)); // deferred track app_web_uuid after app reload
      window.localStorage.setItem(IDFM_STORAGE_KEY, uuid);
    } else {
      Analytics.track('app_web_uuid', { uuid, mode: WEB_UUID_AUTH_MODE.EMAIL_PASSWORD });
      Analytics.setUserProperty('web_source', 'sub');
    }
  };
};

const processAuthResponse = (response: ProfileData) => {
  return (dispatch: AppDispatch) => {
    if (response) {
      const accessToken = _.get(response, 'profile.access_token', null);
      if (accessToken) {
        api.setToken(accessToken);
      }
      dispatch(setProfile(response));

      const userCredentials = {
        id: _.get(response, 'profile.id', ''),
        accessToken: _.get(response, 'profile.access_token', ''),
      };

      dispatch(setUserCredentials(userCredentials));
    }
  };
};

export function processWebUUID({ uuid, isDeferred, source, token }: ProcessWebUuidParams) {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const { webUUID } = getState().auth;
    console.log('>>>>>>> processWebUUID');

    if (isStandalone() && webUUID) {
      return false;
    }

    Analytics.track('process_web_uuid_start', { uuid, platform: Platform.OS });

    //       Analytics.track('process_web_uuid_cancel', { uuid, reason: 'standalone' });

    console.log('> uuid', uuid);
    console.log('> webUUID', webUUID);

    if (uuid === webUUID || !uuid) {
      console.log('>>>>>>> RETURN FALSE. SAME UUID');
      Analytics.track('process_web_uuid_cancel_same_user', { uuid });
      return false;
    }

    if (Platform.OS !== 'web') {
      // для мобилки будет рестарт. Чтобы случайно не залогинить дефолтного пользователя, или не похерить токен, не запускаем авторизацию
      waitForDeeplinkHandle = true;
      const mode = isDeferred ? WEB_UUID_AUTH_MODE.DEEPLINK_DEFERRED : WEB_UUID_AUTH_MODE.DEEPLINK;
      // app_web_uuid на мобилке трекаем сразу (ивенты летят в оного evtruck юзера)
      Analytics.track('app_web_uuid', { uuid, mode });
    }

    if (source) {
      Analytics.setUserProperty('web_source', source);
    }

    dispatch(toggleOverlayLoaderActivity(true));
    try {
      dispatch(resetUserProfileData());
      dispatch(resetAuthData());

      // app_web_uuid на вебе при переходе по линке трекаем после успешной авторизации, так как токен может быть невалидным
      // в ссылке может не быть токена, а только idfm, в таком случае пользователь будет гарантировано авторизован, и мы могли бы здесь сразу трекнуть app_web_uuid.
      // но пусть будет одинаковый флоу для перехода по любой ссылке
      if (Platform.OS === 'web') {
        Analytics.track('process_web_uuid_set_deferred_handle', { uuid });
        dispatch(setDeferredAppWebUuidMode(WEB_UUID_AUTH_MODE.LINK));
      }

      console.log('> set web uuid from processWebUUID action', webUUID);
      dispatch(setWebUUID(uuid));
      if (token) {
        dispatch(setOneTimeToken(token));
      }

      if (Platform.OS !== 'web') {
        setTimeout(() => {
          RNRestart.restart();
        }, 500);
      }

      return true;
    } catch (e) {
      console.log('> Process web uuid error: ', e);
    }
    dispatch(toggleOverlayLoaderActivity(false));
  };
}

export const fakeAuth = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setOnboardingStep({ step: ONBOARDING_IDS.WELCOME }));

    const idfm = await DeviceProps.getIDFM();

    await dispatch(processWebUUID({ uuid: idfm }));
    await dispatch(initAuth());
    await dispatch(initsAfterAuth());

    dispatch(goToNextStep());
  };
};

export const afterReloadHandler = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { isAfterReload },
    } = getState();

    if (!isAfterReload) {
      return false;
    }

    await Analytics.getAttribution();
    dispatch(setAfterReload(false));
  };
};
