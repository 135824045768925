import React, { FC } from 'react';
import { StyleSheet, View, Image } from 'react-native';

import { useAppSelector } from 'store';
import { getPreviewURI } from 'store/palm-reading/selectors';
import PhotoView from 'screens/palm-reading/landing/components/photo-view';
import { HANDS } from 'store/palm-reading/types';

import PDF_ICON from '../../img/pdf.png';

const PhotoPalmView: FC = () => {
  const leftHandReport = useAppSelector(state => state.palmReading?.reports?.left);
  const uri = useAppSelector(state => getPreviewURI(state, HANDS.LEFT));

  if (!leftHandReport?.created_at || !uri) {
    return null;
  }

  return (
    <View style={styles.wrap}>
      <View style={styles.view}>
        <PhotoView hand={HANDS.LEFT} resizeMode={'cover'} />
      </View>
      <Image source={PDF_ICON} style={styles.pdfBadge} />
    </View>
  );
};

export default PhotoPalmView;

const styles = StyleSheet.create({
  wrap: {
    position: 'absolute',
    top: 7,
    left: 7,
    width: 40,
    height: 53,
    borderRadius: 2,
  },
  view: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  pdfBadge: {
    position: 'absolute',
    right: -13,
    bottom: 14,
  },
});
