import { Platform } from 'react-native';
import { CardStyleInterpolators } from '@react-navigation/stack';

import { TRANSPARENT, DARK_BLUE_GREY_2 } from './colors';
import { APP_NAME } from './general';

interface ScreenOptions {
  [key: string]: any;
}

export const DEFAULT_OPTIONS: ScreenOptions = {
  title: APP_NAME,
  headerShown: false,
  gestureEnabled: false,
  cardStyle: { backgroundColor: DARK_BLUE_GREY_2, flex: 1 },
  cardOverlayEnabled: true,
  presentation: 'card',
  cardStyleInterpolator: Platform.select({
    ios: CardStyleInterpolators.forHorizontalIOS,
    android: CardStyleInterpolators.forRevealFromBottomAndroid,
    web: CardStyleInterpolators.forHorizontalIOS,
  }),
};

export const TOP_TABS_SCREEN_OPTIONS = {
  title: APP_NAME,
  swipeEnabled: false,
};

export const MODALS_DEFAULT_OPTIONS = {
  headerShown: false,
  cardStyle: {
    backgroundColor: TRANSPARENT,
  },
  cardOverlayEnabled: true,
  presentation: 'modal',
  cardStyleInterpolator: CardStyleInterpolators.forRevealFromBottomAndroid,
};
