import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import 'intl-pluralrules';
import { getLocales } from 'react-native-localize';

import { DEFAULT_LNG, DEFAULT_NS, LANGUAGES } from './const';
import { languageDetector } from './detector';
import { resources, langLoader } from './translations-map';
import type { SupportedLngs } from './translations-map';
import { setDayjsLocale } from './dayjs';
import { setMomentLocale } from './moment';
import { initCalendars } from './calendars';
import { initWowUI } from './wow-ui';
import type { Translate } from './types';

export { LANGUAGES };
export type { SupportedLngs };

const SHOW_LOGS = false;

const localizationModule = {
  async init() {
    if (i18n.isInitialized) {
      return;
    }

    await i18n
      .use(languageDetector)
      .use(initReactI18next)
      .init({
        resources,
        fallbackLng: DEFAULT_LNG,
        returnObjects: true,
        interpolation: {
          escapeValue: false,
        },
        debug: __DEV__ && SHOW_LOGS,
      });

    setDayjsLocale(this.getLng());
    setMomentLocale(this.getLng());
    initCalendars(this.getLng());
    initWowUI(i18n.t);

    await this.loadLng();
  },

  async setLng(lng: string) {
    await i18n.changeLanguage(lng);
    await this.loadLng();
  },

  getLng() {
    return i18n.resolvedLanguage || DEFAULT_LNG;
  },

  getLngCode() {
    return this.getLng().split('-')[0];
  },

  getSystemLng() {
    return getLocales()[0].languageTag;
  },

  getSystemLngCode() {
    return getLocales()[0].languageCode;
  },

  addTranslations(translations: Record<string, unknown>) {
    if (!translations || !(typeof translations === 'object')) {
      return;
    }

    Object.keys(translations).map(lng => {
      i18n.addResourceBundle(lng, DEFAULT_NS, translations[lng], true, true);
    });
  },

  async loadLng() {
    const lng = this.getLng();

    if (lng !== DEFAULT_LNG) {
      try {
        const translation = await langLoader[lng]?.();

        if (translation && typeof translation === 'object') {
          i18n.addResourceBundle(lng, DEFAULT_NS, translation, true, false);
        }
      } catch (err) {
        console.log(err);
      }
    }
  },

  supportedLngs: Object.keys(resources) as SupportedLngs[],
};

export const t = i18n.t as Translate;
export const t2 = i18n.t;

export default localizationModule;
