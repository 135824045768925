import DeviceProps from '@magnus/react-native-device-props';
import EvTruck from '@magnus/react-native-evtruck';
import Mutator from '@magnus/react-native-mutator';
import * as Sentry from '@sentry/browser';
import Session from '@wowmaking/react-native-analytics/src/core/helpers/session';
import { createPromise } from '@wowmaking/react-native-analytics/src/core/utils/create-promise';
import * as Firebase from 'firebase/analytics';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';
import AsyncStorage from '@react-native-async-storage/async-storage';
import type { AnyAction } from 'redux';
import type { AnalyticsType } from '@wowmaking/react-native-analytics/types/core';
import dayjs from 'dayjs';

import { processWebUUID } from 'modules/auth/store/actions';
import { setPromocode } from 'store/astrologers/core/actions';
import type { StoreType } from 'store';
import { ProcessWebUuidParams } from 'modules/auth/store/types';
import Analytics from 'analytics/index';

import packageInfo from '../../web/package.json';

import { SHOW_EVENTS_LOGS } from './const';
import type { AnalyticsModule, WebTransaction, UserPropName } from './types';
import type { EventParams } from './_events';

const KEY_COHORT_DATE = '@WRNA/COHORT_DATE';
const USER_PROPERTIES_KEY = '@RNMV/USER_PROPERTIES';
const isGRA = window.navigator?.userAgent?.includes?.('Google-Read-Aloud');

if (!isGRA && ['localhost', '0.0.0.0', '127.0.0.1'].indexOf(window.location.hostname) === -1) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1,
  });
}

const USER_PROPS: UserPropName[] = ['utm_source', 'campaign_id', 'ua_creative_topic', 'mode', 'testmode'];

let store: StoreType;

const analyticsModule: AnalyticsModule = {
  ready: createPromise(),

  cohortDate: null,

  putStore(s) {
    store = s;
  },

  async init() {
    console.log('>>> ANALYTICS INIT START');
    if (isGRA) {
      return Promise.resolve();
    }

    DeviceProps.getIDFM().then(idfm => {
      Sentry.setUser({ id: idfm });

      window.dataLayer?.push({
        idfm,
      });
    });

    DeviceProps.setAppVersion(packageInfo.version);

    EvTruck.init({
      token: process.env.REACT_APP_MAGNUS_TOKEN || '',
    });

    try {
      await Session.init(this as unknown as AnalyticsType);

      const query = queryString.parse(window.location.search);
      const pathname = window.location.pathname;
      const referrer = document.referrer;
      const partition = pathname.slice(1);

      Mutator.setAttribution({ source: String(query.utm_source), campaign_id: String(query.campaign_id) });

      this.setUserProperty('timezone', new Date().getTimezoneOffset() / 60);
      this.setUserProperty('domain', window.location.hostname);
      if (partition) {
        this.setUserProperty('partition', partition);
      }

      if (query.source) {
        this.setUserProperty('web_source', String(query.source));
      }

      USER_PROPS.forEach(prop => {
        const value = query[prop];
        if (value) {
          this.setUserProperty(prop, String(value));
        }
      });

      if (query.promocode) {
        store.dispatch(setPromocode(query.promocode));
      }

      if (typeof query.idfm === 'string' || typeof query.token === 'string') {
        const params = { uuid: query.idfm, token: query.token } as ProcessWebUuidParams;
        store.dispatch(processWebUUID(params) as unknown as AnyAction);
      }

      Analytics.trackEvent('web_session', 'url_params', { ...query, pathname, referrer });

      try {
        delete query.idfm;
        delete query.token;
        delete query.promocode;
        createBrowserHistory().replace(
          queryString.stringifyUrl({
            url: window.location.pathname,
            query,
          }),
        );
      } catch (e) {
        console.log('> Clear search params error', e);
      }

      this.cohortDate = await this.getCohortDate();
      this.ready.resolve();
    } catch (e) {
      console.log('> Analytics init [ERROR]', e);
    }
  },

  onAdjustDeferredDeepLink() {},

  setAttributionUserProperties() {},

  setUserProperty(name, value) {
    EvTruck.setUserProperty(name, value);
    Firebase.setUserProperties(Firebase.getAnalytics(), { [name]: value });
  },

  async getUserProperty(key) {
    try {
      const stringifiedUserProperties = await AsyncStorage.getItem(USER_PROPERTIES_KEY);

      if (!stringifiedUserProperties) {
        throw 'User Properties value not found';
      }

      const userProperties = await JSON.parse(stringifiedUserProperties);

      return userProperties?.[key];
    } catch (error) {
      console.log('ERROR getUserProperty', error);
    }
  },

  trackEvent(category, action, params) {
    const event = `${category}_${action}`;
    params = {
      ...params,
      session_number: this.getSessionNumber(),
    };

    // if (__DEV__ && SHOW_EVENTS_LOGS) {
    //   console.log(`[Platform: WEB] [${dayjs().format('HH:mm:ss')}]:`, event, params);
    // }

    EvTruck.trackEvent(event, params);
    window.dataLayer?.push({ event, ...params }); // GTM
    Firebase.logEvent(Firebase.getAnalytics(), event, params);
  },

  track(...args) {
    const [event, params, options] = args;

    const [category, ...rest] = event.split('_');
    const action = [...rest].join('_');

    this.trackEvent(category, action, params, options);
  },

  trackViewProductEvent() {},

  trackPurchaseEvent(transaction) {
    if (isWebTransaction(transaction)) {
      EvTruck.trackEvent('ecommerce_purchase', {
        transactionId: transaction.transactionId,
        productId: transaction.productId,
        value: transaction.revenue,
        currency: transaction.currency,
      });

      window.dataLayer?.push({
        event: 'purchase',
        currency: transaction.currency,
        value: Number(transaction.revenue),
        transaction_id: transaction.transactionId,
        product_id: transaction.productId,
      });
    }
  },

  trackPaymentEvent(category, action, params) {
    const eventName = `${category}_${action}` as keyof EventParams;
    this.track(eventName, params);
  },

  getSessionNumber() {
    return Session.getNumber();
  },

  getAppRef() {
    const p = queryString.parse(window.location.search);

    if (p.ref) {
      return String(p.ref);
    }
  },

  getAdjustDeferredDeeplink() {
    return Promise.resolve(null);
  },

  async getCohortDate() {
    try {
      const res = await AsyncStorage.getItem(KEY_COHORT_DATE);
      return res;
    } catch (error) {
      console.log('ERROR getCohortDate', error);
      return null;
    }
  },

  async getAttribution() {
    try {
      const attribution = await EvTruck.fetchWebAttribution();

      if (attribution?.campaign_id) {
        this.setUserProperty('campaign_id', String(attribution?.campaign_id));
      }

      if (attribution?.utm_source) {
        this.setUserProperty('utm_source', String(attribution?.utm_source));
      }

      if (attribution?.url) {
        const params = queryString.parse(attribution.url);
        if (params?.ua_creative_topic) {
          this.setUserProperty('ua_creative_topic', String(params.ua_creative_topic));
        }
      }
    } catch (error) {
      console.log('ERROR getAttribution', error);
    }
  },
};

const isWebTransaction = (t: any): t is WebTransaction => {
  return !!t && 'currency' in t && 'revenue' in t;
};

export default analyticsModule;
