import { AUTH_TYPES, State, ActionType } from './types';

const initialState: State = {
  id: '',
  webUUID: '',
  oneTimeToken: '',
  accessToken: '',
  isSuccessful: false,
  deferredAppWebUuidMode: '', // for deferred track app_web_uuid
  prevEvtruckUser: '',
  forceLogout: false, // force navigate to AUTHORIZATION
  firebaseEmail: '',
  isRegistrationCompleted: false, // has email and password
  completeRegistrationShowsCounter: 0,
  isCompleteRegistrationShown: false,
  isAfterReload: false, // shows if app after auth reload,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case AUTH_TYPES.SET_USER_CREDENTIALS:
      return {
        ...state,
        id: action.payload.id,
        oneTimeToken: '',
        accessToken: action.payload.accessToken,
        isSuccessful: true,
        forceLogout: false,
      };
    case AUTH_TYPES.SET_WEB_UUID:
      console.log('> SET_WEB_UUID', action.payload);
      return {
        ...state,
        webUUID: action.payload,
      };
    case AUTH_TYPES.SET_ONE_TIME_TOKEN:
      return {
        ...state,
        accessToken: '',
        oneTimeToken: action.payload,
      };
    case AUTH_TYPES.SET_FIREBASE_EMAIL:
      return {
        ...state,
        firebaseEmail: action.payload,
      };
    case AUTH_TYPES.SET_DEFERRED_APP_WEB_UUID_MODE:
      console.log('> SET_DEFERRED_APP_WEB_UUID_MODE', action.payload);
      return {
        ...state,
        deferredAppWebUuidMode: action.payload,
      };
    case AUTH_TYPES.SET_PREV_EVTRUCK_USER:
      return {
        ...state,
        prevEvtruckUser: action.payload,
      };
    case AUTH_TYPES.SET_FORCE_LOGOUT:
      return {
        ...state,
        forceLogout: true,
      };
    case AUTH_TYPES.SET_IS_REGISTRATION_COMPLETED:
      return {
        ...state,
        isRegistrationCompleted: action.payload,
      };
    case AUTH_TYPES.SET_COMPLETE_REGISTRATION_SHOW_COUNTER:
      return {
        ...state,
        completeRegistrationShowsCounter: action.payload,
      };
    case AUTH_TYPES.SET_COMPLETE_REGISTRATION_SHOWN:
      return {
        ...state,
        isCompleteRegistrationShown: action.payload,
      };
    case AUTH_TYPES.SET_AFTER_RELOAD:
      return {
        ...state,
        isAfterReload: action.payload,
      };
    case AUTH_TYPES.RESET_DATA:
      console.log('> AUTH_TYPES.RESET_DATA');
      return {
        ...initialState,
      };
  }

  return state;
}
