import React, { FC, useState, useEffect } from 'react';
import { StyleSheet, Image, StyleProp, ViewStyle, TouchableOpacity, View, Dimensions } from 'react-native';
import * as Animatable from 'react-native-animatable';
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { paddingHorizontal, paddingVertical, sw, color } from '@wowmaking/ui/src/utils';
import { isNumber } from 'lodash';

import { UserReport } from 'store/upsails/types';
import withHaptic from 'hoc/with-haptic';
import * as COLORS from 'constants/colors';
import { REPORTS_PRODUCT_CODES } from 'constants/upsale';
import Icon from 'components/icon';
import Text from 'components/text';
import GiftBadge from 'components/gift-badge';
import { getDiffSecondsFromNow } from 'utils/date';

import LOADING_LINE_IMG from '../img/loading-line.png';

import PhotoPalmView from './photo-palm-view';
import Timer from './timer';

const { width } = Dimensions.get('window');

const pulse: Animatable.CustomAnimation<{ [key: string]: number }> = {
  0: {
    scale: 0.1,
    opacity: 0,
  },
  0.75: {
    scale: 1,
    opacity: 1,
  },
  1: {
    opacity: 0,
  },
};

const slideRightOut: Animatable.CustomAnimation<{ [key: string]: number }> = {
  0: {
    translateX: -100,
  },
  1: {
    translateX: width,
  },
};
const AnimatedText = Animated.createAnimatedComponent(Text);

interface Props {
  report: UserReport;
  disabled: boolean;
  withAnimation?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress: (productCode: UserReport['productCode'], isTimeUp: boolean) => void;
}

const PersonalReportsItem: FC<Props> = ({ report, style, withAnimation, disabled, onPress }) => {
  const { productCode, title, image, readyTimer, readyDate, isGift, isViewed } = report;
  const [isActive, setIsActive] = useState(!readyTimer);

  useEffect(() => {
    if (readyDate) {
      setIsActive(getDiffSecondsFromNow(readyDate) <= 0);
    }
  }, [readyDate]);

  const animatedTextStyle = useAnimatedStyle(() => {
    return {
      opacity: isActive ? withTiming(1) : withTiming(0.5),
    };
  }, [isActive]);

  const handlePress = () => {
    if (disabled && !isActive) {
      return;
    }
    onPress(productCode, isActive);
  };

  const handleTimeEnd = () => {
    setIsActive(true);
  };

  return (
    <TouchableOpacity style={[styles.wrap, style]} onPress={handlePress}>
      {isGift && <GiftBadge style={styles.giftBadge} />}
      <View style={[styles.container, isGift && styles.containerGift]}>
        {withAnimation && isActive && (
          <Animatable.View
            animation={slideRightOut}
            iterationCount="infinite"
            iterationDelay={2000}
            duration={2000}
            easing="ease-in-out-cubic"
            style={styles.loadingAnimation}>
            <Image source={LOADING_LINE_IMG} style={styles.loadingAnimationImg} />
          </Animatable.View>
        )}

        <View style={styles.imageWrap}>
          <Image source={{ uri: image }} style={styles.image} />

          {report.productCode === REPORTS_PRODUCT_CODES.PALM_READING && <PhotoPalmView />}
        </View>
        <AnimatedText style={[styles.title, animatedTextStyle]} font="Philosopher">
          {title}
        </AnimatedText>

        <View style={styles.arrowWrap}>
          {withAnimation && isActive && (
            <Animatable.View
              animation={pulse}
              duration={1500}
              iterationDelay={500}
              easing="ease-in-out"
              iterationCount="infinite"
              style={styles.animationArrow}
            />
          )}

          <Icon name={'arrow'} size={sw(16)} color={COLORS.BEIGE} />
        </View>
      </View>
      {isNumber(readyTimer) && !!readyDate && <Timer date={readyDate} isViewed={isViewed} onTimeEnd={handleTimeEnd} />}
    </TouchableOpacity>
  );
};

export default withHaptic(PersonalReportsItem);

const styles = StyleSheet.create({
  wrap: {
    width: '100%',
    marginBottom: paddingVertical(10),
  },
  container: {
    width: '100%',
    backgroundColor: COLORS.DARK_GREY_BLUE,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    height: 50,
    paddingRight: paddingHorizontal(40),
    overflow: 'hidden',
  },
  containerGift: {
    paddingRight: paddingHorizontal(80),
  },
  giftBadge: {
    right: paddingHorizontal(10),
    top: -sw(7),
  },
  loadingAnimation: {
    width: 120,
    height: '100%',
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  loadingAnimationImg: {
    height: '100%',
  },
  imageWrap: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 90,
    height: 50,
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  title: {
    paddingLeft: paddingHorizontal(76),
    flexShrink: 1,
    color: COLORS.BEIGE,
    fontSize: 16,
    fontWeight: '700',
  },
  arrowWrap: {
    width: 50,
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: -paddingHorizontal(5),
  },
  animationArrow: {
    position: 'absolute',
    width: 50,
    height: 50,
    borderRadius: 50,
    backgroundColor: color(COLORS.TEAL2, 0.05),
  },
});
