import { AspectId, PlanetId, ASCENDANT } from '@wowmaking/birthchart';
import type { Ascendant } from '@wowmaking/birthchart';

import ASCENDANT_COLOR_ICON from './images/color-ascendant.png';
import MATTE_JUPITER_ICON from './images/color-jupiter.png';
import MATTE_MARS_ICON from './images/color-mars.png';
import MATTE_MERCURY_ICON from './images/color-mercury.png';
import MATTE_MOON_ICON from './images/color-moon.png';
import MATTE_NEPTUNE_ICON from './images/color-neptune.png';
import MATTE_PLUTO_ICON from './images/color-pluto.png';
import MATTE_SATURN_ICON from './images/color-saturn.png';
import MATTE_SUN_ICON from './images/color-sun.png';
import MATTE_URANUS_ICON from './images/color-uranus.png';
import MATTE_VENUS_ICON from './images/color-venus.png';
import CONJUNCTION_ICON from './images/conjunction.png';
import OPPOSITION_ICON from './images/opposition.png';
import SEXTILE_ICON from './images/sextile.png';
import SQUARE_ICON from './images/square.png';
import TRINE_ICON from './images/trine.png';
import TRANSITS_JUPITER from './images/planets/icon-jupiter.png';
import TRANSITS_MARS from './images/planets/icon-mars.png';
import TRANSITS_MERCURY from './images/planets/icon-mercury.png';
import TRANSITS_MOON from './images/planets/icon-moon.png';
import TRANSITS_NEPTUNE from './images/planets/icon-neptune.png';
import TRANSITS_PLUTO from './images/planets/icon-pluto.png';
import TRANSITS_SATURN from './images/planets/icon-saturn.png';
import TRANSITS_SUN from './images/planets/icon-sun.png';
import TRANSITS_URANUS from './images/planets/icon-uranus.png';
import TRANSITS_VENUS from './images/planets/icon-venus.png';
import ASCENDANT_ICON from './images/icons/ascendant-icon.png';
import JUPITER_ICON from './images/icons/jupiter-icon.png';
import MARS_ICON from './images/icons/mars-icon.png';
import MERCURY_ICON from './images/icons/mercury-icon.png';
import MOON_ICON from './images/icons/moon-icon.png';
import NEPTUNE_ICON from './images/icons/neptune-icon.png';
import PLUTO_ICON from './images/icons/pluto-icon.png';
import SATURN_ICON from './images/icons/saturn-icon.png';
import SUN_ICON from './images/icons/sun-icon.png';
import URANUS_ICON from './images/icons/uranus-icon.png';
import VENUS_ICON from './images/icons/venus-icon.png';
import ASCENDANT_IMG from './images/ascendant.png';
import JUPITER_IMG from './images/jupiter.png';
import MARS_IMG from './images/mars.png';
import MERCURY_IMG from './images/mercury.png';
import MOON_IMG from './images/moon.png';
import NEPTUNE_IMG from './images/neptune.png';
import PLUTO_IMG from './images/pluto.png';
import SATURN_IMG from './images/saturn.png';
import SUN_IMG from './images/sun.png';
import URANUS_IMG from './images/uranus.png';
import VENUS_IMG from './images/venus.png';
import { PlanetImages, AspectsImages } from './interfaces';

export const MAP_PLANET_TO_ICON: PlanetImages = {
  [ASCENDANT]: ASCENDANT_COLOR_ICON,
  [PlanetId.Mars]: MATTE_MARS_ICON,
  [PlanetId.Venus]: MATTE_VENUS_ICON,
  [PlanetId.Mercury]: MATTE_MERCURY_ICON,
  [PlanetId.Moon]: MATTE_MOON_ICON,
  [PlanetId.Sun]: MATTE_SUN_ICON,
  [PlanetId.Pluto]: MATTE_PLUTO_ICON,
  [PlanetId.Jupiter]: MATTE_JUPITER_ICON,
  [PlanetId.Saturn]: MATTE_SATURN_ICON,
  [PlanetId.Uranus]: MATTE_URANUS_ICON,
  [PlanetId.Neptune]: MATTE_NEPTUNE_ICON,
};

export const MAP_TRANSITS_ICON: Omit<PlanetImages, Ascendant> = {
  [PlanetId.Mars]: TRANSITS_MARS,
  [PlanetId.Venus]: TRANSITS_VENUS,
  [PlanetId.Mercury]: TRANSITS_MERCURY,
  [PlanetId.Moon]: TRANSITS_MOON,
  [PlanetId.Sun]: TRANSITS_SUN,
  [PlanetId.Pluto]: TRANSITS_PLUTO,
  [PlanetId.Jupiter]: TRANSITS_JUPITER,
  [PlanetId.Saturn]: TRANSITS_SATURN,
  [PlanetId.Uranus]: TRANSITS_URANUS,
  [PlanetId.Neptune]: TRANSITS_NEPTUNE,
};

export const PLANET_IMAGES: PlanetImages = {
  [ASCENDANT]: ASCENDANT_IMG,
  [PlanetId.Jupiter]: JUPITER_IMG,
  [PlanetId.Mars]: MARS_IMG,
  [PlanetId.Mercury]: MERCURY_IMG,
  [PlanetId.Moon]: MOON_IMG,
  [PlanetId.Neptune]: NEPTUNE_IMG,
  [PlanetId.Pluto]: PLUTO_IMG,
  [PlanetId.Saturn]: SATURN_IMG,
  [PlanetId.Sun]: SUN_IMG,
  [PlanetId.Uranus]: URANUS_IMG,
  [PlanetId.Venus]: VENUS_IMG,
};

export const PLANET_ICONS: PlanetImages = {
  [ASCENDANT]: ASCENDANT_ICON,
  [PlanetId.Jupiter]: JUPITER_ICON,
  [PlanetId.Mars]: MARS_ICON,
  [PlanetId.Mercury]: MERCURY_ICON,
  [PlanetId.Moon]: MOON_ICON,
  [PlanetId.Neptune]: NEPTUNE_ICON,
  [PlanetId.Pluto]: PLUTO_ICON,
  [PlanetId.Saturn]: SATURN_ICON,
  [PlanetId.Sun]: SUN_ICON,
  [PlanetId.Uranus]: URANUS_ICON,
  [PlanetId.Venus]: VENUS_ICON,
};

export const MAP_ASPECTS_TO_ICON: AspectsImages = {
  [AspectId.Square]: SQUARE_ICON,
  [AspectId.Sextile]: SEXTILE_ICON,
  [AspectId.Trine]: TRINE_ICON,
  [AspectId.Opposition]: OPPOSITION_ICON,
  [AspectId.Conjunction]: CONJUNCTION_ICON,
};

export const CHART_MODAL_INFO = {
  TITLE: 'BIRTH_CHART.MODAL.TITLE',
  POINTS: [
    {
      id: 'DESC_0',
      text: 'BIRTH_CHART.MODAL.INFO_POINTS.DESC_0',
    },
    {
      id: 'DESC_1',
      text: 'BIRTH_CHART.MODAL.INFO_POINTS.DESC_1',
    },
    {
      id: 'DESC_2',
      text: 'BIRTH_CHART.MODAL.INFO_POINTS.DESC_2',
    },
  ],
};

export enum TOP_TABS_IDS {
  TRANSITS = 'transits',
  CHARTS = 'charts',
}

export const TOP_TABS: TOP_TABS_IDS[] = [TOP_TABS_IDS.TRANSITS, TOP_TABS_IDS.CHARTS];

export const PERSONALITY_PLANETS: (PlanetId | Ascendant)[] = [PlanetId.Sun, PlanetId.Moon, ASCENDANT];

export const STELLAR_PLANETS: PlanetId[] = [
  PlanetId.Mercury,
  PlanetId.Venus,
  PlanetId.Mars,
  PlanetId.Jupiter,
  PlanetId.Saturn,
  PlanetId.Uranus,
  PlanetId.Neptune,
  PlanetId.Pluto,
];

export const ASPECTS_MAJOR: AspectId[] = [AspectId.Conjunction, AspectId.Opposition, AspectId.Trine, AspectId.Square];
export const ASPECTS_MINOR: AspectId[] = [AspectId.Quincunx, AspectId.Quintile, AspectId.Semisextile, AspectId.Semisquare];

export const MIN_BIRTH_DATE = '1920-01-01';
