import api from 'api';

import { GET_HISTORY, HistoryItem, Astrologist } from './constants';

const getHistory = async (id: Astrologist['astrologer_id'], receiveTarot: boolean = true, page: number = 0): Promise<HistoryItem[]> => {
  try {
    const response = await api.fetch(`${GET_HISTORY}?astrologer_id=${id}&page=${page}&receiveTarot=${receiveTarot}`, {
      method: 'GET',
    });

    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] getHistory', error);
    return [] as HistoryItem[];
  }
};

export default getHistory;
