import { AppStateStatus } from 'react-native';

import { PROFILE_TYPES } from 'store/profile/types';

export const SET_IS_FULL_PROFILE = 'SET_IS_FULL_PROFILE';
export const SET_INTERNET_CONNECTION = 'SET_INTERNET_CONNECTION';
export const SET_IS_LOADED = 'SET_IS_LOADED';
export const SET_APP_STATUS = 'SET_APP_STATUS';
export const SET_WAS_ON_CONTENT_PAGES = 'SET_WAS_ON_CONTENT_PAGES';
export const SET_OVERLAY_LOADER_ACTIVITY = 'SET_OVERLAY_LOADER_ACTIVITY';
export const SET_ROLL_UP_PUSH_ON_DASHBOARD_SHOWN = 'SET_ROLL_UP_PUSH_ON_DASHBOARD_SHOWN';
export const SET_INITIAL_SYSTEM_LOCALE = 'SET_INITIAL_SYSTEM_LOCALE';
export const SET_IS_ANALYTICS_INITED = 'SET_IS_ANALYTICS_INITED';
export const SET_INITIAL_URL = 'SET_INITIAL_URL';
export const SET_INSTALL_VERSION = 'SET_INSTALL_VERSION';

export interface State {
  isInternetConnected: boolean;
  isFullProfile: boolean;
  isLoaded: boolean;
  isAnalyticsInited: boolean;
  appStatus: AppStateStatus;
  wasOnContentPages: boolean;
  isOverlayLoaderActive: boolean;
  isRollUpPushOnDashboardShown: boolean;
  initialSystemLocale: string | null;
  initialUrl: string | null;
  installVersion: string | null;
}

interface SetIsFullProfile {
  type: typeof SET_IS_FULL_PROFILE;
}

interface SetInternetConnection {
  type: typeof SET_INTERNET_CONNECTION;
  payload: boolean;
}

interface SetIsLoaded {
  type: typeof SET_IS_LOADED;
}

interface SetAppStatus {
  type: typeof SET_APP_STATUS;
  payload: AppStateStatus;
}

interface SetWasOnContentPages {
  type: typeof SET_WAS_ON_CONTENT_PAGES;
}

interface SetOverlayLoaderActivity {
  type: typeof SET_OVERLAY_LOADER_ACTIVITY;
}

interface ResetUserData {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

interface SetRollUpPushOnDashboardShown {
  type: typeof SET_ROLL_UP_PUSH_ON_DASHBOARD_SHOWN;
}

interface SetInitialSystemLocale {
  type: typeof SET_INITIAL_SYSTEM_LOCALE;
  payload: string;
}

interface SetIsAnalyticsInited {
  type: typeof SET_IS_ANALYTICS_INITED;
}

interface SetInitialUrl {
  type: typeof SET_INITIAL_URL;
  payload: string | null;
}

interface SetInstallVersion {
  type: typeof SET_INSTALL_VERSION;
  payload: string;
}

export type ActionType =
  | SetIsFullProfile
  | SetInternetConnection
  | SetIsLoaded
  | SetAppStatus
  | SetWasOnContentPages
  | SetOverlayLoaderActivity
  | SetRollUpPushOnDashboardShown
  | SetInitialSystemLocale
  | ResetUserData
  | SetIsAnalyticsInited
  | SetInitialUrl
  | SetInstallVersion;
