import {
  getAuth,
  OAuthProvider,
  getAdditionalUserInfo,
  signInWithRedirect,
  linkWithRedirect,
  getRedirectResult,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  updatePassword,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithEmailLink,
} from 'firebase/auth';

import { FIREBASE_AUTH_ERRORS } from '../constants';
import { FirebaseProviderId } from '../types';


const auth = {
  getCurrentUser: async () => {
    const idToken = await getAuth().currentUser?.getIdToken(true);
    if (idToken) {
      const { email, uid: uuid } = (await getAuth().currentUser) || {};
      return { idToken, email, uuid };
    }

    return new Promise(resolve => {
      onAuthStateChanged(getAuth(), async user => {
        const idToken = await user?.getIdToken(true);
        if (idToken) {
          const { email, uid: uuid } = user || {};
          resolve({ idToken, email, uuid });
        }
      });

      // чтобы вечно не ждать резолва промиса, если вдруг что-то пойдет не так, тут установим таймаут
      setTimeout(() => {
        resolve(null);
      }, 3000);
    });
  },
  signInWithCustomToken: (custom_token: string) => {
    return signInWithCustomToken(getAuth(), custom_token);
  },
  signInWithEmailAndPassword: (email: string, password: string) => {
    return signInWithEmailAndPassword(getAuth(), email, password);
  },
  signInWithGoogle: async (): Promise<void> => {
    const provider = new GoogleAuthProvider();
    return signInWithRedirect(getAuth(), provider);
  },
  getRedirectResult: async (): Promise<null> => {
    console.log('>>>>>>>>> getRedirectResult 2');

    try {
      const result = await getRedirectResult(getAuth());
      console.log('> result', result);

      const user = result ? result.user : null;
      console.log('> user', user);
      console.log('> result.additionalUserInfo', result?.additionalUserInfo);

      // console.log('> result 2', result);
      // const credential = OAuthProvider.credentialFromResult(result);
      // console.log('> credential', credential);
      // console.log('> providerId', credential?.providerId);
      // const user = credential ? credential.user : null;

      const idToken = await user?.getIdToken(true);
      const { isNewUser } = getAdditionalUserInfo(result);
      console.log('> user', user);
      console.log('> idToken', idToken);
      console.log('> email', user.email);
      console.log('> isNewUser', isNewUser);
      return { idToken, isNewUser, email: user.email };
    } catch (e) {
      console.log('> [AUTH] getRedirectResult ERROR', e);
      return null;
    }
  },
  sendPasswordResetEmail: (email: string) => {
    return sendPasswordResetEmail(getAuth(), email);
  },
  signInWithEmailLink: (email: string, emailLink: string) => {
    return signInWithEmailLink(getAuth(), email, emailLink);
  },
  getFirebaseErrorCode: (input: string): FIREBASE_AUTH_ERRORS => {
    if (input.includes('auth/user-not-found')) {
      return FIREBASE_AUTH_ERRORS.USER_NOT_FOUND;
    }

    if (input.includes('auth/invalid-email') || input.includes('Email not found')) {
      return FIREBASE_AUTH_ERRORS.INVALID_EMAIL;
    }

    if (input.includes('auth/wrong-password')) {
      return FIREBASE_AUTH_ERRORS.WRONG_PASSWORD;
    }

    if (input.includes('auth/too-many-requests')) {
      return FIREBASE_AUTH_ERRORS.TOO_MANY;
    }

    return FIREBASE_AUTH_ERRORS.DEFAULT;
  },
  getSingInMethods: (email: string) => {
    return fetchSignInMethodsForEmail(getAuth(), email);
  },
  updateUserPassword: (password: string) => {
    const user = getAuth()?.currentUser;

    if (!user) {
      return false;
    }

    return updatePassword(user, password);
  },
};

export default auth;
