import api from 'api';

import { ADD_TIME, AddPromocodeRequestParams, AddPromocodeResponse } from './constants';

const addPromocode = async ({ code, increaseFreeBalance = false }: AddPromocodeRequestParams): Promise<AddPromocodeResponse | null> => {
  try {
    const response = await api.fetch(ADD_TIME, {
      method: 'POST',
      data: {
        code,
        freeBalance: increaseFreeBalance,
      },
    });

    return 'code' in response ? response : null;
  } catch (err) {
    console.log('[ERROR ADD PROMOCODE]', err);
    return null;
  }
};

export default addPromocode;
