import React, { FC, useMemo } from 'react';
import { DimensionValue, Modal, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import { AppDispatch, RootState } from 'store';
import { isSolidgatePaymentService } from 'store/billing/selectors';
import { closeCurrentModal } from 'store/modals/actions';
import Iframe from 'components/web/iframe';
import * as MODALS from 'constants/modals';
import { isDevUrl } from 'utils/astrologist';
import useModalIsVisible from 'hooks/use-modal-is-visibly';

interface Props {}

const WebAstrologersOneTimeModal: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const chatId = useSelector((state: RootState) => state.astrologers.chat.currentChatId);
  const idfm = useSelector((state: RootState) => state.auth.webUUID);
  const renewData = useSelector((state: RootState) => state.astrologers.monetization.renewData);
  const email = useSelector((state: RootState) => state.profile.profileData?.email);
  const isSolidgate = useSelector(isSolidgatePaymentService);

  const isVisible = useModalIsVisible(MODALS.ONE_TIME);

  const url = useMemo(() => {
    if (!renewData) {
      return '';
    }

    const { amount, currency, productCode } = renewData;

    const baseUrl = isDevUrl(window.location.hostname) ? process.env.REACT_APP_WEB_ASB_STAGE_URL : window.location.origin;

    const query = {
      amount,
      currency: currency.toLocaleUpperCase(),
      skipOneClick: !isSolidgate ? !isSolidgate : undefined,
      idfm,
      product: productCode,
      email,
      chatId,
    };

    const uri = queryString.stringifyUrl({
      url: `${baseUrl}/asb/one-time-payment` as unknown as string,
      query,
    });

    return uri;
  }, [chatId, email, idfm, isSolidgate, renewData]);

  const closeModal = () => {
    dispatch(closeCurrentModal());
  };

  return (
    <Modal visible={isVisible} style={styles.iframe_modal}>
      <Iframe id="astrologersOneTimeIframe" src={url} onError={closeModal} />
    </Modal>
  );
};

export default WebAstrologersOneTimeModal;

const styles = StyleSheet.create({
  iframe_modal: {
    width: '100vw' as DimensionValue,
    height: '100vh' as DimensionValue,
    top: 0,
    left: 0,
  },
});
