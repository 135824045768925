import queryString from 'query-string';

const IDFM_KEY = '@RNMV/IDFM';

const isStandalone = () => {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return true; // Trusted web app
  } else if (('standalone' in window.navigator && window.navigator.standalone) || isStandalone) {
    return true;
  }
  return false;
};

(function () {
  try {
    console.log('>>> URL SYNC');
    const idfm = window.localStorage.getItem(IDFM_KEY);
    const isPWA = isStandalone();
    console.log('> storage idfm', idfm);

    // Для pwa записываем только один раз, чтобы избежать проблем с релогином
    if (isPWA && idfm) {
      return false;
    }

    const query = queryString.parse(window.location.search);
    console.log('> query idfm', query.idfm);

    if (query.idfm) {
      console.log('>>> URL SYNC. SET IDFM', query.idfm);
      window.localStorage.setItem(IDFM_KEY, query.idfm);
    }
  } catch (e) {
    console.log('> URL Sync Error', e);
  }
})();
