import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  isOnboardingCompleted: false,
  onboardingProfile: {},
  step: '',
  goal: '',
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET_ONBOARDING_PROFILE:
      return {
        ...state,
        onboardingProfile: action.payload,
      };

    case TYPES.SET_IS_ONBOARDING_COMPLETED:
      return {
        ...state,
        isOnboardingCompleted: true,
      };

    case TYPES.SET_ONBOARDING_STEP:
      return {
        ...state,
        step: action.payload.step,
      };

    case TYPES.RESET_ONBOARDING_DATA:
      return initialState;

    default:
      return state;
  }
}
