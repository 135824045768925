import React, { FC, memo, useState, useEffect } from 'react';
import { Image, ImageResizeMode, Platform, StyleSheet } from 'react-native';

import { useAppSelector } from 'store';
import { HANDS } from 'store/palm-reading/types';
import { getPreviewURI } from 'store/palm-reading/selectors';
import { getDocumentDirectory } from 'utils/cache-manager';

import LEFT_DEFAULT from '../../../assets/images/hands/left.png';
import RIGHT_DEFAULT from '../../../assets/images/hands/right.png';

interface Props {
  hand: HANDS;
  onError?: () => void;
  resizeMode?: ImageResizeMode;
}

const PhotoView: FC<Props> = ({ hand, onError, resizeMode = 'cover' }) => {
  const [error, setError] = useState<boolean>(false);
  const uri = useAppSelector(state => getPreviewURI(state, hand));
  const defaultHand = HANDS.RIGHT === hand ? RIGHT_DEFAULT : LEFT_DEFAULT;

  useEffect(() => {
    if (uri) {
      setError(false);
    }
  }, [uri]);

  const handleError = () => {
    setError(true);

    if (typeof onError === 'function') {
      onError();
    }
  };

  const url = Platform.OS === 'web' ? uri : getDocumentDirectory() + uri?.split('/')?.pop();
  const source = url && !error ? { uri: url } : defaultHand;

  return <Image style={styles.root} source={source} onError={handleError} resizeMode={resizeMode} />;
};

export default memo(PhotoView);

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
  },
});
